import React from "react"
import { Link } from "gatsby"
import moment from "moment"
import { useActualTime } from "../../../services/hooks/useActualTime"

import cta from "../utils/homeCTA.json"
import { hasSignedInUser } from "../../Auth/services/user"

const HomeCTAButton = () => {
  const patientStagingEndDate = "2025-03-06 11:00"
  const patientProdEndDate = "2025-03-15 23:59"
  const epharmacyStagingEndDate = "2025-04-07 11:59"
  const epharmacyProdEndDate = "2025-04-30 23:59"

  const patientFinalEndDate =
    process.env.GATSBY_ENV === "staging"
      ? patientStagingEndDate
      : patientProdEndDate
  const epharmacyFinalEndDate =
    process.env.GATSBY_ENV === "staging"
      ? epharmacyStagingEndDate
      : epharmacyProdEndDate

  const patientEnrollEndDate = moment(patientFinalEndDate, "YYYY-MM-DD HH:mm")
  const orderEndDate = moment(epharmacyFinalEndDate, "YYYY-MM-DD HH:mm")
  const time = useActualTime()

  const isAfterPatientEnrollEndDate = time.isAfter(patientEnrollEndDate)
  const isAfterOrderEndDate = time.isAfter(orderEndDate)

  if (isAfterOrderEndDate) return null

  return cta.map((cta) => (
    <div className="mb-2">
      <h3 className="subtitle has-text-weight-bold has-text-primary is-size-3">
        {cta?.title}
      </h3>
      <div className="buttons">
        {cta?.links.map((link) =>
          !link.signedIn &&
          !!hasSignedInUser() ? null : isAfterPatientEnrollEndDate &&
            link.label.includes("Enroll") ? null : isAfterOrderEndDate &&
            link.label.includes("Order") ? null : (
            <Link
              to={link.url}
              className={`button is-medium is-primary is-${link?.type}`}
            >
              {link.label}
            </Link>
          )
        )}
      </div>
      <p>
        {cta?.subtitle}
        <Link to={cta?.title.toLowerCase()} className="has-text-weight-bold">
          {` ${"Learn more"}.`}
        </Link>
      </p>
    </div>
  ))
}

export default HomeCTAButton
