import React, { useContext, useEffect } from "react"
import { useStaticQuery, graphql } from "gatsby"
import { GATSBY_ORDER_AS_GUEST_KEY } from "gatsby-env-variables"

import Hero from "layout/Hero"
import Container from "layout/Container"
import HomeCTAButton from "./HomeCTAButton"
import { isBrowser } from "../../../services/general"
import { AppContext } from "../../../context/AppContext"

const HomeBanner = () => {
  const data = useStaticQuery(graphql`
    {
      heart: file(relativePath: { eq: "index__hero--heart.jpg" }) {
        childImageSharp {
          fluid(
            duotone: { highlight: "#ffffff", shadow: "#0d6d6e" }
            toFormat: PNG
            quality: 90
            maxWidth: 1000
          ) {
            ...GatsbyImageSharpFluid
          }
        }
      }
    }
  `)
  const heart = data?.heart?.childImageSharp?.fluid?.src
  const { dispatch } = useContext(AppContext)

  useEffect(() => {
    if (isBrowser()) {
      window.sessionStorage.removeItem(GATSBY_ORDER_AS_GUEST_KEY)
    }
    dispatch({
      type: "SHOW_MODAL",
      payload: {
        heading: "Pulse Care Program Update",
        isCard: true,
        headerClass: `has-text-info has-background-info-light has-text-weight-bold is-size-5`,
        content: (
          <div className="has-text-left">
            <p className="is-size-6">
              Good news! The Pulse Care Program is continuing until&nbsp;
              <span className="has-text-weight-bold">April 30, 2025&nbsp;</span>
              You can continue to order your Sacubitril-Valsartan as we finalize
              the new scheme to ensure a smooth transition. If you have any
              questions, please contact us at pulsecare@medgrocer.com or
              09178482088.
            </p>
            <div className="buttons is-centered mt-2">
              <button
                className="button is-primary is-outlined"
                onClick={() => dispatch({ type: "HIDE_MODAL" })}
              >
                Close
              </button>
            </div>
          </div>
        ),
      },
    })
  }, [])

  return (
    <Hero
      size="medium"
      background={{
        image: heart,
        position: "center",
      }}
    >
      <Container isCentered desktop={12} fullhd={8}>
        <div className="columns">
          <div className="column is-8-desktop">
            <h3 className="title">
              {
                "The Pulse Care Program is a heart failure management and medicine adherence program."
              }
            </h3>

            <HomeCTAButton />
          </div>
        </div>
      </Container>
    </Hero>
  )
}

export default HomeBanner
